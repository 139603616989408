<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          :table-filter="{label: $t('table_filter')}"
          :items-per-page-select="{label: $t('items_page')}"
        >
        <template #no-items-view>
            <div class="d-flex align-items-center justify-content-center my-3">
                <span class="mr-2" style="font-size: 30px; font-weight: 600; ">{{ $t('no_items') }}</span>
                <CIcon name="cil-ban" class="text-danger" style="width: 35px; height: 35px;" />
              </div>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const moment = require("moment");
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
export default {
  name: "ListSafetyCheck",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    tableItems: Array,
    countryCodes: Array,
    countryChossed: String,
  },
  computed: {
    _country() {
      return this.countryA;
    },
  },
  data() {
    return {
      layout: "list",
      tableTitle: this.$i18n.t("safety_checks"),
      tableFields: [
        { key: "checkedOptions", label: this.$t('checked') },
        { key: "uncheckedOptions", label: this.$t('unchecked') },
        { key: "comment", label: this.$t('comments') },
        { key: "eventDate", label: this.$t('event_date') },
        { key: "system", label: this.$t('system') },
        { key: "countryCode", label: this.$t('country_code') },
        { key: "truckPlateNo", label: this.$t("truck_plate") },
        { key: "driver_name", label: this.$t("driver") },
        { key: "driver_email", label: this.$t("email") },
        { key: "driver_license", label: this.$t("license") },
        { key: "driver_sapId", label: this.$t("sap_id") },
      ],

      listStatus: [],
    };
  },
  async created() {
    const self = this;
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}

.buttons {
  display: flex;
}
</style>